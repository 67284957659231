export function flattenChildren<T extends { children?: T[] }>(values: T[]): Omit<T, 'children'>[] {
  return values.reduce(
    (memo: Omit<T, 'children'>[], { children, ...value }) => [
      ...memo,
      value,
      ...flattenChildren(children || []),
    ],
    [],
  );
}
