import type { IntlShape } from 'react-intl';
import type { FeatureToggleableLink } from '@aph/components/common/link-list/link-list.component';
import type { NavItem } from '@aph/components/common/navigator/navigator-items.utils';
import type { NavItemVariant } from './nav-items';
import {
  loggedInMemberNavItems,
  loggedInNotMemberNavItems,
  notLoggedInNavItems,
} from './nav-items';

export const getNavItems = (
  isAuthenticated: boolean | null | undefined,
  isMember: boolean | null | undefined,
  intl: IntlShape,
  variant: NavItemVariant,
): Array<FeatureToggleableLink> => {
  const toCombinedNavItem = (navItem: NavItem) => {
    const title = intl.messages[navItem.name].toString();
    return {
      ...navItem,
      title,
      name: title,
      href: navItem.url,
      url: navItem.url,
    };
  };

  let navItems = notLoggedInNavItems;

  if (isAuthenticated) {
    navItems = isMember ? loggedInMemberNavItems : loggedInNotMemberNavItems;
  }

  return navItems
    .filter((menuItem) => menuItem.variant === variant)
    .map((menuItem) => toCombinedNavItem(menuItem));
};
