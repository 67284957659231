/* eslint-disable no-nested-ternary */
import React from 'react';
import { useRouter } from 'next/router';
import type { FormControlProps, SelectChangeEvent } from '@mui/material';
import { FormControl, InputLabel, MenuItem as MUIMenuItem, Select, Skeleton } from '@mui/material';
import { parseEventTree, sendMainNavigationEvent } from '@aph/components/gtm/events/navigation-gtm';
import { Typography } from '@aph/ui/components/typography/typography';

type MenuItem = {
  name: string;
  url: string;
  isEpiLink?: boolean;
};

type MobileMenuProps = {
  menuItems?: Array<MenuItem>;
  isMenuItemsLoading?: boolean;
} & Pick<FormControlProps, 'sx'>;

export const MobileMenu = ({ menuItems, isMenuItemsLoading, ...props }: MobileMenuProps) => {
  const router = useRouter();

  const currentHref = router.asPath;

  if (isMenuItemsLoading) {
    return <Skeleton height="56px" width="100%" sx={{ transform: 'scale(1)' }} {...props} />;
  }

  if (!menuItems) {
    return null;
  }

  const onMenuItemPress = async (e: SelectChangeEvent<string>) => {
    const navItem = menuItems.find((x) => x.url === e.target.value);
    const url = navItem?.url || e.target.value;
    if (navItem?.isEpiLink != null) {
      if (navItem.isEpiLink) {
        window.location.href = navItem.url;
      }
      await router.push(url);
    } else {
      await router.push(url);
    }
  };

  return (
    <FormControl fullWidth {...props}>
      <InputLabel>
        <Typography color="text-subtle">Navigering</Typography>
      </InputLabel>
      <Select
        data-testid="NAVIGATOR.MOBILE.SELECT"
        sx={{
          height: '56px',
          borderRadius: '10px',
          background: (theme) => theme.palette['color/background/elevated'],
          fieldset: { border: 0, borderWidth: 0 },
          '.MuiSelect-select': {
            paddingBottom: 0.75,
            color: (theme) => theme.palette['color/text/default'],
            fontWeight: '400',
          },
          '.MuiFormLabel-root': { paddingTop: 0.75 },
        }}
        label="Navigering"
        value={currentHref}
        onChange={(e) => {
          onMenuItemPress(e);
          sendMainNavigationEvent(parseEventTree(e.target.value));
        }}
        MenuProps={{
          marginThreshold: 0,
        }}
      >
        {menuItems.map((item) => (
          <MUIMenuItem key={item.name} data-testid="NAVIGATOR.MOBILE.MENU.ITEM" value={item.url}>
            {item.name}
          </MUIMenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
