import React from 'react';
import { useRouter } from 'next/router';
import { Breadcrumbs as MUIBreadcrumbs } from '@mui/material';
import type { BreadcrumbsProps as MUIBreadcrumbsProps } from '@mui/material';
import { useIntl } from 'react-intl';
import { Typography } from '@aph/ui/components/typography/typography';
import { flattenChildren } from '@aph/utilities/flatten-children';
import { urlify } from '@aph/utilities/slug';
import { FeatureToggledNextLink } from '../common/feature-toggled-next-link';

type MenuItem = {
  name: string;
  url: string;
  children?: Array<MenuItem>;
  isEpiLink?: boolean;
};

function generateBreadcrumbs(path: string, menuItems: Array<MenuItem>): Array<MenuItem> {
  const asPathNestedRoutes = path.split('/').filter((v) => v.length > 0);

  const crumblist = asPathNestedRoutes.reduce(
    (acc, subpath) => {
      const url = `${acc.slugMemo}${subpath}/`;
      const menuItem = menuItems.find((item) => item.url === url);
      if (!menuItem) {
        return { breadcrumbs: acc.breadcrumbs, slugMemo: url };
      }
      return {
        breadcrumbs: [...acc.breadcrumbs, { url, name: menuItem.name }],
        slugMemo: url,
      };
    },
    { breadcrumbs: [] as Array<MenuItem>, slugMemo: '/' },
  );
  return crumblist.breadcrumbs;
}
type CrumbProps = { name: string; url?: string; last?: boolean };
const Crumb = ({ name, url, last = false }: CrumbProps) => {
  if (last || !url) {
    return <Typography className="font-bold">{name}</Typography>;
  }
  return <FeatureToggledNextLink href={url}>{name}</FeatureToggledNextLink>;
};

type BreadcrumbsProps = MUIBreadcrumbsProps & {
  menuItems?: Array<MenuItem>;
  pathsForBreadcrumbsMissingInMenuItems?: Array<MenuItem>;
};
export const Breadcrumbs = ({
  menuItems,
  pathsForBreadcrumbsMissingInMenuItems = [],
  ...props
}: BreadcrumbsProps) => {
  const router = useRouter();
  const intl = useIntl();
  if (!menuItems) {
    return null;
  }

  const flat = flattenChildren([...menuItems, ...pathsForBreadcrumbsMissingInMenuItems]);
  const breadcrumbs = [
    { url: '/', name: intl.messages['COMMON.BREADCRUMBS.HOME'].toString() },
    ...generateBreadcrumbs(
      router.asPath,
      flat.map((item) => ({ ...item, url: urlify(item.url) })),
    ),
  ];
  return (
    <MUIBreadcrumbs {...props} aria-label="breadcrumb">
      {breadcrumbs.map(({ name, url }, idx) => (
        <Crumb name={name} url={url} key={url} last={idx === breadcrumbs.length - 1} />
      ))}
    </MUIBreadcrumbs>
  );
};
