import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  useAmIMemberActions,
  useAmIMemberStore,
} from '~/model/member/am-i-member/use-am-i-member-store';
import { getNavItems } from './nav-items-utils';

export const useClubMemberNavItems = () => {
  const intl = useIntl();
  const { amIMember } = useAmIMemberStore();
  const { getAmIMember } = useAmIMemberActions();
  const isAuthenticated =
    amIMember.data && (amIMember.data.isMember === true || amIMember.data.isMember === false);
  const isMember = amIMember.data && amIMember.data.isMember;

  useEffect(() => {
    if (amIMember.updated === 0) {
      getAmIMember();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const primaryNavItems = useMemo(
    () => getNavItems(isAuthenticated, isMember, intl, 'primary'),
    [isAuthenticated, isMember, intl],
  );
  const secondaryNavItems = useMemo(
    () => getNavItems(isAuthenticated, isMember, intl, 'secondary'),
    [isAuthenticated, isMember, intl],
  );
  const allNavItems = [...primaryNavItems, ...secondaryNavItems].map((item) => ({
    ...item,
    url: item.href,
    name: item.title,
  }));

  return { primaryNavItems, secondaryNavItems, allNavItems, isAuthenticated };
};
