import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { allNavItems } from '../nav-items/nav-items';

export const DesktopMenuSkeleton: React.FC = () => (
  <Box padding={1.25}>
    <Stack gap={1}>
      {allNavItems.map((item) => (
        <Skeleton key={item.name} variant="text" height={30} />
      ))}
    </Stack>
  </Box>
);
