import Box from '@mui/material/Box';
import type { FeatureToggleableLink } from '@aph/components/common/link-list/link-list.component';
import { LinkList } from '@aph/components/common/link-list/link-list.component';
import { parseEventTree, sendMainNavigationEvent } from '@aph/components/gtm/events/navigation-gtm';
import { DesktopMenuSkeleton } from './dekstop-menu-skeleton.component';

type DesktopMenuProps = {
  isLoading: boolean;
  primaryNavItems: Array<FeatureToggleableLink>;
  secondaryNavItems: Array<FeatureToggleableLink>;
};

export const DesktopMenu: React.FC<DesktopMenuProps> = ({
  isLoading,
  primaryNavItems,
  secondaryNavItems,
}) => {
  if (isLoading) return <DesktopMenuSkeleton />;
  return (
    <Box component="nav" aria-label="Klubb Hjärtat meny">
      <Box padding={1.25}>
        <LinkList
          links={primaryNavItems}
          variant="primary"
          onLinkClick={(target) => sendMainNavigationEvent(parseEventTree(target.href))}
        />
      </Box>
      <Box padding={1.25}>
        <LinkList
          links={secondaryNavItems}
          variant="secondary"
          onLinkClick={(target) => sendMainNavigationEvent(parseEventTree(target.href))}
        />
      </Box>
    </Box>
  );
};
